import React, { useState, useRef } from "react";
import "./main.scss";
import faqImage from "../../assets/img/FaqImg.png";
import HeaderWithPaw from "../../components/revamp/Header";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const contentRefs = useRef([]); // Store references to FAQ answers

  const faqs = [
    {
      question: "How do I setup an account?",
      answer: (
        <>
          <ol>
            <li>1. Download the Unleash app through Google Play Store or Apple Store.</li>
            <li>2. Register with your email, or log in with your Facebook ID, Google ID, or Apple ID.</li>
            <li>3. Enter your Full Name, Birthdate, Gender, and Age.</li>
          </ol>
        </>
      ),
    },
    {
      question: "How can I retrieve my account if I forgot my password?",
      answer:
        "To recover your password, go to the email login screen and click “Forgot Password”. Enter your registered email address and wait for the email with the link to reset your password",
    },
    {
      question: "How do I send treats to pets/user profiles?",
      answer: "To send treats, you will need to buy Unleash Bits.",
    },
    {
      question: "How do I purchase Unleash Bits?",
      answer: "To purchase Unleash Bits, tap the treats Icon, tap Bits Balance and choose the amount you'd like to purchase.",
    },
    {
      question: "How do I pay for Unleash Bits?",
      answer: "You can pay via Apple Pay for IOS or Google Pay for Android devices.",
    },
    {
      question: "How to register as a partner-merchant?",
      answer:
        "To register as a partner-merchant, you may send us an email at partnerships@unleash.ph.",
    },
    {
      question: "How is the pay-out process?",
      answer:
        "Seller payouts are paid via bank transfer to the account you have provided when you signed up as a partner-merchant. Please note that the actual crediting of funds in your bank account depends on your bank's processing time.",
    },
    {
      question: "What is the refund and return policy?",
      answer: (
        <>
          <ol>
            <li>1. Customers must contact our customer service team at support@unleash.ph to initiate a return/refund request.</li>
            <li>2. Provide the order number, product details, and reason for the return/refund request.</li>
            <li>3. Our team will guide customers through the return process if applicable.</li>
          </ol>
        </>
      ),
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq faq-container">
      <div className="faq-content">
        <div className="faq-list">
          <HeaderWithPaw paw={false} title="Frequently Asked Questions" />
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <button className="faq-question" onClick={() => toggleFAQ(index)}>
                <span className="question-text">{faq.question}</span>
                <span className="icon">{openIndex === index ? "↑" : "↓"}</span>
              </button>
              <div
                className="faq-answer-container"
                ref={(el) => (contentRefs.current[index] = el)}
                style={{
                  height: openIndex === index ? contentRefs.current[index]?.scrollHeight : 0,
                  transition: "height 0.4s ease-in-out",
                  overflow: "hidden",
                }}
              >
                <div className="faq-answer">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="faq-image">
          <img src={faqImage} alt="FAQ Illustration" />
        </div>
      </div>
    </div>
  );
};

export default FAQ;