import { IconText, OurServicesCard, RewardsInApp } from "../../components/revamp/Cards";
import HeaderWithPaw from "../../components/revamp/Header";
import { PARTNER_LOGOS, REWARDS, SERVICES } from "../../variables/OurServicesList";
import app_holding from "../../assets/img/our_services/ecommerce/app_holding.png"
import meet_our_team from "../../assets/img/our_services/team/meet-our-team.png"
// import Profile from "../../components/Profile";
import "./main.scss"
import Slider from "react-slick";
import pawbg from "../../assets/img/paw-bg.png"
import mini_paw from "../../assets/img/our_services/text_paw.png";

export default function OurServices() {
    const os_settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const re_settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // const pr_settings = {
    //     infinite: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     dots: false,
    //     autoplay: true,
    //     autoplaySpeed: 4000,
    //     responsive: [
    //         {
    //             breakpoint: 1200,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 960,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 700,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //     ],
    // };

    return (
        <div className="our-services" id="our-services">
            <HeaderWithPaw title="Our Services" />
            <div className="centered-section our-services">
                <Slider {...os_settings}>
                    {
                        // map through the SERVICES list to generate services cards.
                        SERVICES.map((service) =>
                            <OurServicesCard
                                icon_active={service.icon_active}
                                icon_inactive={service.icon_inactive}
                                title={service.title}
                                description={service.description} />)
                    }
                </Slider>
            </div>
            <br />

            <HeaderWithPaw title="Rewards In App" />
            <p className="subtitle">Earn fantastic rewards for your pet from all the paw-some treats you receive in the app!</p>
            <div className="centered-section our-services">
                <Slider {...re_settings}>
                    {
                        // map through the REWARDS list to generate rewards cards.
                        REWARDS.map((reward) =>
                            <RewardsInApp
                                img={reward.img}
                                title={reward.title}
                                cost={reward.cost} />)
                    }
                </Slider>
            </div>

            <br />

            {/* <HeaderWithPaw title="Rapidly Growing E-Commerce" />
            <div className="centered-section">
                <div className="ecommerce">
                    Shop everything your pet needs from over 10,000 trusted merchants. Find products such as food and toys as well as services like vets, hotels and trainers - all in one convenient app!
                </div>
            </div> */}

            <br />

            <div className="benefits relative">
                <div className="benefits-columns relative z-10">
                    <HeaderWithPaw title="Benefits" />
                    <br />
                    <img
                        src={pawbg}
                        alt="paw"
                        className="absolute top-14 left-0 w-36 h-auto object-cover z-[-1]"
                    />
                    <IconText
                        icon={require("../../assets/img/our_services/ecommerce/borader-connection.png")}
                        title="Broader Connection"
                        content="Fosters a supportive community for pet owners to share experiences, ask questions, and make friends." />
                    <IconText
                        icon={require("../../assets/img/our_services/ecommerce/information-hub.png")}
                        title="Information Hub"
                        content="Provides access to reliable pet care information, tips, and advice." />
                    <IconText
                        icon={require("../../assets/img/our_services/ecommerce/online-shopping.png")}
                        title="Online Shopping Convenience"
                        content="Easy access to essential pet products and services, simplifying pet care." />
                </div>
                <div className="benefits-columns">
                    <img
                        src={mini_paw}
                        alt="paw"
                        className="absolute top-[-5rem] right-28 w-8 h-8 object-cover z-10"
                    />
                    <img src={app_holding} alt="Icon" className="hand-img" />
                </div>
            </div>



            <br />

            <div className="centered-section centered-section-relative meet-our-team">
                <img src={meet_our_team} alt="background" className="image" />
                <div className="content">
                    <HeaderWithPaw title="About Our Team" white={true} paw={false} justify="LEFT" textSize="text-5xl" />
                    <p className="description">
                        We're a passionate group <br />
                        dedicated to creating <br />something that will transform <br />the way you and your furry <br />friends experience the world<br /> together.
                        {/* Here are the faces behind the app that's set to unleash joy in the lives of pets and their humans alike! */}
                    </p>

                    {/* <div className="list">
                        <Slider {...pr_settings}>
                            {
                                STAFF_PROFILES.map((profile) =>
                                    <Profile
                                        pet_img={profile.petImage}
                                        staff_img={profile.staffImage}
                                        name={profile.name}
                                        position={profile.position}
                                    />
                                )
                            }
                        </Slider>
                    </div> */}
                </div>
            </div>

            <br />

            <div className="our-trusted-partners">
                <HeaderWithPaw title="Our Trusted Partners" />
                <div className="gap" />
                <div className="container">
                    <div className="image-container">
                        <img
                            src={PARTNER_LOGOS.UAS.img}
                            alt={PARTNER_LOGOS.UAS.company}
                            className="partners"
                            onClick={() => window.open(PARTNER_LOGOS.UAS.link, "_blank")} />
                    </div>
                    <div className="image-container">
                        <img
                            src={PARTNER_LOGOS.E_PLDT.img}
                            alt={PARTNER_LOGOS.E_PLDT.company}
                            className="partners"
                            onClick={() => window.open(PARTNER_LOGOS.E_PLDT.link, "_blank")} />
                    </div>
                    <div className="image-container">
                        <img
                            src={PARTNER_LOGOS.HUAWEI_CLOUD.img}
                            alt={PARTNER_LOGOS.HUAWEI_CLOUD.company}
                            className="partners relative z-10"
                            onClick={() => window.open(PARTNER_LOGOS.HUAWEI_CLOUD.link, "_blank")} />
                        <img
                            src={mini_paw}
                            alt="paw"
                            className="paw-design" />
                    </div>
                </div>
            </div>
        </div>
    )
}