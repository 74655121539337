// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.min.css';
// import AppRouter from './routes';
// import { HelmetProvider } from 'react-helmet-async';

// function App() {
//   return (
//     <HelmetProvider>
//       <AppRouter />
//     </HelmetProvider>
//   );
// }

// export default App;

import FrontPage from "./pages/FrontPage";
import PrivacyPolicy from "./pages/PrivatePolicy";
import TermsConditions from "./pages/TermsConditions";
import SafetyTips from "./pages/SafetyTips";
import CookiePolicy from "./pages/CookiePolicy";
import CommunityGuidelines from "./pages/CommunityGuidelines";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/revamp/Navbar";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar>
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/safety-tips" element={<SafetyTips />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/community-guidelines" element={<CommunityGuidelines />} />
          </Routes>
        </Navbar>
      </BrowserRouter>
    </div>
  );
}

export default App;
