const SERVICES = [
    {
        id: "pet-community",
        icon_active: require("../assets/img/our_services/our_services/pet-community/active.png"),
        icon_inactive: require("../assets/img/our_services/our_services/pet-community/inactive.png"),
        title: "Pet Community",
        description: "Discover a vibrant community of pet lovers where you can showcase your pet’s unique personality. Engage with fellow pet owners through likes, comments, and exciting trending challenges!"
    },
    {
        id: "the-animall",
        icon_active: require("../assets/img/our_services/our_services/the-animall/active.png"),
        icon_inactive: require("../assets/img/our_services/our_services/the-animall/inactive.png"),
        title: "The AniMall",
        description: "Access a curated selection of premium pet food and accessories, all in one place. Shop seamlessly with fast, reliable delivery and unlock exclusive deals and discounts—available only through the app!"
    },
    {
        id: "play",
        icon_active: require("../assets/img/our_services/our_services/play/active.png"),
        icon_inactive: require("../assets/img/our_services/our_services/play/inactive.png"),
        title: "Play",
        description: "Play entertaining pet-themed games directly in the app. Relax, unwind, and celebrate your love for pets in an engaging and rewarding way."
    },
    {
        id: "news-and-blogs",
        icon_active: require("../assets/img/our_services/our_services/news-and-blogs/active.png"),
        icon_inactive: require("../assets/img/our_services/our_services/news-and-blogs/inactive.png"),
        title: "News And Blogs",
        description: "Discover fun articles, tips, and stories about pets while sharing your own adorable moments. Upload and share videos of your furry friends, and join a community of pet lovers exchanging ideas and experiences!"
    },
    {
        id: "meets",
        icon_active: require("../assets/img/our_services/our_services/meets/active.png"),
        icon_inactive: require("../assets/img/our_services/our_services/meets/inactive.png"),
        title: "Meets",
        description: "Share your pet's unique traits and find matches who also understand the bond between owner and furry friend. Whether it’s a shared love for dogs, cats, or exotic pets, discover meaningful connections based on both your personalities and the pets you adore!"
    }
]

const REWARDS = [
    {
        img: require("../assets/img/our_services/rewards_in_app/reward/0.png"),
        title: "Brit Premium Gravy Fillet With Turkey 85g Cat Wet Food",
        cost: 19200
    },
    {
        img: require("../assets/img/our_services/rewards_in_app/reward/1.png"),
        title: "Happy Pet Nourishing Skin Care  Gel",
        cost: 79350
    },
    {
        img: require("../assets/img/our_services/rewards_in_app/reward/2.png"),
        title: "Harley’s Dehydrated Duck Feet Dog Treats 70g",
        cost: 26250
    },
    {
        img: require("../assets/img/our_services/rewards_in_app/reward/3.png"),
        title: "Buddy & Me Paw And Snout Balm Cocoa Shea Butter 60g",
        cost: 35850
    }
    
]

const STAFF_PROFILES = [
    {
        petImage: require("../assets/img/our_services/team/profiles/placeholder_gray.png"),
        staffImage: require("../assets/img/our_services/team/profiles/placeholder_white.png"),
        name: "Chie Malaki",
        position: "Chief Executive Officer"
    },
    {
        petImage: require("../assets/img/our_services/team/profiles/placeholder_gray.png"),
        staffImage: require("../assets/img/our_services/team/profiles/placeholder_white.png"),
        name: "Muji Jafaar",
        position: "Chief Operations Officer"
    },
    {
        petImage: require("../assets/img/our_services/team/profiles/placeholder_gray.png"),
        staffImage: require("../assets/img/our_services/team/profiles/placeholder_white.png"),
        name: "Ana Marie Aguirre",
        position: "E-Commerce Director"
    },
    {
        petImage: require("../assets/img/our_services/team/profiles/placeholder_gray.png"),
        staffImage: require("../assets/img/our_services/team/profiles/placeholder_white.png"),
        name: "Mark Llauder",
        position: "Acquisitions Director"
    },
    {
        petImage: require("../assets/img/our_services/team/profiles/placeholder_gray.png"),
        staffImage: require("../assets/img/our_services/team/profiles/placeholder_white.png"),
        name: "Cherubic Anne P. Fuertes",
        position: "Project Manager"
    }
]

const PARTNER_LOGOS = {
    UAS: {
        company: "UAS",
        img: require("../assets/img/our_services/partners/uas_logo.png"),
        link: "https://uas.com.ph/"
    },
    E_PLDT:{
        company: "ePLDT",
        img: require("../assets/img/our_services/partners/e_pldt_logo.png"),
        link: "https://www.epldt.com/"
    },
    HUAWEI_CLOUD: {
        company: "Huawei",
        img: require("../assets/img/our_services/partners/huawei_logo.png"),
        link: "https://cloud.huawei.com/"
    }
}

export {SERVICES, REWARDS, STAFF_PROFILES, PARTNER_LOGOS}