import text_paw from "../../assets/img/our_services/text_paw.png";
import "./main.scss";

const CENTER = "CENTER";
export default function HeaderWithPaw({ title, white = false, paw = true, justify = CENTER, textSize = ""}) {
    return (
        <div className={(white ? "header-white " : "header ") + (justify===CENTER? "justify-center items-center " : "justify-start items-start ") + textSize}>
            <p className={textSize}>{title}</p>
            {
                paw?<img src={text_paw} alt="TextPaw" className="header-paw" />:null
            }
        </div>
    );
}