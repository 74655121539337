import React, { useState } from "react";
import "./main.scss";
import joinUsImage from "../../assets/img/JoinUsImg.png";
import HeaderWithPaw from "../../components/revamp/Header";

const options = [
  {
    number: "01.",
    label: "PARTNER MERCHANT",
    content: (
      <>
        <ol>
          <h1>Offer pet supplies & services, reach thousands of loving pet owners, and:</h1>
          <li>Boost sales: Tap into a dedicated pet market with high purchasing power.</li>
          <li>Enhanced visibility: Promote your brand & stand out from competitors.</li>
          <li>Convenient booking: Streamline client bookings & appointments.</li>
          <li>Loyal customers: Build relationships & foster repeat business.</li>
          <h1>Join us, and let's create a happier, healthier life for pets (and their humans)!</h1>
        </ol>
      </>
    ),
  },
  {
    number: "02.",
    label: "PARTNER ORGANIZATION",
    content: (
      <>
        <ol>
          <h1>Join us in Unleash and empower pet owners to provide exceptional care! As our partner, you'll gain:</h1>
          <li>Increased visibility: Reach millions of engaged pet lovers daily.</li>
          <li>Direct impact and support: Support your mission through in-app fundraising and pet adoptions. Drive donations and adoptions through seamless in-app integration.</li>
          <li>Massive Reach: Enhance your network and connect with millions of engaged pet lovers, amplifying your mission.</li>
          <li>Unique Insights: Understand pet owner trends and tailor your outreach accordingly.</li>
          <li>Join us, and let's create a happier, healthier life for pets (and their humans)!</li>
          <h1>Together, let's create a happier, healthier world for all animals!</h1>
        </ol>
      </>
    ),
  },
  {
    number: "03.",
    label: "INVESTOR",
    content: (
      <>
        <ol>
          <h1>Here's why you should join the pack:</h1>
          <li>Convenience Unleashed: Streamlining pet care simplifies lives, attracting a loyal user base.</li>
          <li>Personalized Attention: Your app tailors to individual needs, fostering deeper pet-owner bonds.</li>
          <li>Growth Potential: The pet industry booms yearly, offering a vast, lucrative market to tap into.</li>
          <li>Scalability Potential: Expanding services and regions can unlock exponential growth.</li>
          <li>Join us, and let's create a happier, healthier life for pets (and their humans)!</li>
          <h1>Let's discuss how the investment can help the app fetch success!</h1>
        </ol>
      </>
    ),
  }
];

const JoinUs = () => {
  const [openSections, setOpenSections] = useState(null);

  const toggleSection = (index) => {
    setOpenSections((prev) => (prev === index ? null : index));
  };

  return (
    <div className="join-us container d-flex align-items-center" id="join-us">
      <div className="row w-100">
        {/* Image Section */}
        <div className="col-md-6 text-center">
          <img src={joinUsImage} alt="Join Us" className="img-fluid" />
        </div>

        {/* Text Section */}
        <div className="col-md-6 text-section">
          <HeaderWithPaw title="Join Us" paw={false} justify="LEFT" />
          <p className="description">
            Passionate about pets & growing your business? Join our vibrant
            community of pet merchants and reach thousands of engaged pet
            parents searching for the best products and services.
          </p>
          <div className="options">
            {options.map((option, index) => (
              <div
                key={index}
                className={`option ${openSections === index ? "open" : ""}`}
              >
                <div className="label" onClick={() => toggleSection(index)}>
                  <span className="number">{option.number}</span>
                  {option.label}
                </div>
                <div className="content">{openSections === index && option.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
