import FAQ from "../pages/sections/FAQ.js"; 
import AboutUs from "./sections/AboutUs.js"; 
import ContactUs from "./sections/ContactUs.js";
import HeroSection from "./sections/HeroSection.js";
import JoinUs from "./sections/JoinUs.js";
import OurServices from "./sections/OurServices.js";

export default function FrontPage() {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <OurServices />
      <JoinUs />
      <ContactUs />
      <FAQ />
    </>
  );
}
