import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./main.scss";
import Image1 from "../../assets/img/article1.png"; 
import Image2 from "../../assets/img/article2.png";
import HeaderWithPaw from "../../components/revamp/Header";

const aboutUsSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    fade: false,
    adaptiveHeight: true,
    speed: 600,
    pauseOnHover: true,
    responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 1, arrows: false } },
        { breakpoint: 768, settings: { slidesToShow: 1, dots: false } },
    ],
};

const pets = [
    { id: 1, img: require("../../assets/img/about-us/pet-community/0.png") },
    { id: 2, img: require("../../assets/img/about-us/pet-community/1.png") },
    { id: 4, img: require("../../assets/img/about-us/pet-community/2.png") },
    { id: 5, img: require("../../assets/img/about-us/pet-community/3.png") },
    { id: 6, img: require("../../assets/img/about-us/pet-community/4.png") }
];

const aboutUsArticles = [
    { id: 1, img: Image1, link: "https://www.bulgaronline.com/post/uas-invests-in-unleash-a-groundbreaking-pet-lifestyle-app-to-provide-filipinos-pet-companions-peac" },
    { id: 2, img: Image2, link: "https://www.gizguide.com/2025/02/pldt-techforce-super-app-for-pets.html" },
];

export default function AboutUs() {
    const [sliderKey, setSliderKey] = useState(0);


    const pet_settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        variableWidth: false,
        responsive: [
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 350,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 3000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    useEffect(() => {
        setSliderKey(prevKey => prevKey + 1);
    }, []);

    return (
        <div className="about-us" id="about-us">
            {/* Pet Community Section */}
            <section className="pet-community">
                <HeaderWithPaw title="Pet Community" />
                <Slider key={sliderKey} {...pet_settings}>
                    {pets.map((pet) => (
                        <div key={pet.id} className="pet-slide gradient-hover">
                            <img src={pet.img} alt="Pet" className="pet-image" />
                        </div>
                    ))}
                </Slider>
            </section>

            {/* About Us Section */}
            <section className="about-us-section">
                <div className="inner">
                    <div className="first-half">
                        <HeaderWithPaw title="About Us" />
                        <h3>Learn About All The Great Things We’ve Done For Pets.</h3>
                        <p>
                            Unleash is more than just a pet app - it’s a vibrant community dedicated
                            to celebrating the lives of our furry (and feathery!) friends. Founded
                            by pet lovers, this all-in-one platform combines fun features like
                            e-commerce, social networking, entertainment, and essential information
                            of various pet activities that pet owners need to know.
                        </p>
                    </div>

                    <div className="second-half">
                        <Slider key={sliderKey + 1} {...aboutUsSettings} className="about-us-slider">
                            {aboutUsArticles.map((article) => (
                                <div key={article.id} className="about-us-slide">
                                    <div className="about-us-card">
                                        <a href={article.link} target="_blank" rel="noopener noreferrer">
                                            <img src={article.img} alt="Article" className="about-us-image" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
};
