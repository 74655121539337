import banner from '../assets/img/privacy_policy/banner.png';
import { motion } from 'framer-motion';

const SafetyTips = () => {
  const sections = [
    {
      title: "Protect Personal Information",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Avoid sharing sensitive personal information about yourself or your pet, such as your home address or phone number.</li>
          <li>2. Be cautious when sharing details about your pet's routine or habits that could potentially compromise their safety.</li>
        </ul>
      )
    },
    {
      title: "Mind Your Photos and Videos",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Be mindful of the content you share. Avoid posting photos or videos that could be embarrassing or compromising for your pet.</li>
          <li>2. Refrain from sharing images that reveal sensitive information, such as collar tags or microchip details.</li>
        </ul>
      )
    },
    {
      title: "Be Wary of Strangers",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Exercise caution when interacting with strangers on social media platforms. Not everyone has good intentions.</li>
          <li>2. Avoid sharing personal information or engaging in private conversations with individuals you don't know and trust.</li>
        </ul>
      )
    },
    {
      title: "Monitor Comments and Messages",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Regularly monitor comments and messages on your pet's social media profile for any signs of inappropriate behavior or harassment.</li>
          <li>2. Report users who engage in abusive or threatening behavior toward you or your pet.</li>
        </ul>
      )
    },
    {
      title: "Avoid Overexposure",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Limit the frequency and extent of your pet's exposure on social media to protect their privacy and well-being.</li>
          <li>2. Remember that while sharing is caring, pets also deserve moments of peace and privacy away from the spotlight.</li>
        </ul>
      )
    },
    {
      title: "Be Mindful of Health and Safety",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Prioritize your pet's health and safety over social media engagement. Avoid putting your pet at risk for the sake of a photo or video.</li>
          <li>2. Ensure that any props or accessories used in photoshoots are safe and pet-friendly.</li>
        </ul>
      )
    },
    {
      title: "Promote Positive Messaging",
      content: (
        <ul className="list-none mt-2 space-y-1 text-black pl-6 md:pl-12 text-justify">
          <li>1. Use your pet's social media platform as a tool to promote positive messages and responsible pet ownership within the community.</li>
          <li>2. Share educational content and resources to raise awareness about pet safety, health, and welfare.</li>
        </ul>
      )
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <main className="pt-0">
        {/* Hero Section */}
        <div className="relative h-[300px] md:h-[400px] overflow-hidden">
          <img
            src={banner}
            alt="Privacy Policy Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>

        {/* Safety Tips Text */}
        <div className="relative pt-8 pb-4 text-center px-4">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl text-unleash-blue font-semibold text--unleash-blue mb-2 max-w-3xl mx-auto font-montserrat"
          >
            Safety Tips
          </motion.h1>
          <br />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-6 text-center max-w-[80rem] mx-auto px-4"
          >
            Unleash is a social media platform dedicated to pets and is a fun and enriching way to share moments and connect with fellow pet lovers. However, it's important to prioritize the safety and well-being of your beloved pets. Here are some essential safety tips for pet owners of Unleash:
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-8 text-center max-w-[80rem] mx-auto px-4"
          >
            By following these safety tips, you can enjoy the benefits of pet social media platforms while safeguarding the well-being of your furry companions. Remember, your pet's safety and happiness should always come first!
          </motion.p>
        </div>

        {/* Content Section */}
        <div className="max-w-[90rem] mx-auto px-4 sm:px-6 md:px-12 lg:px-16 py-12 pt-8 pb-20">
          <div className="grid grid-cols-1 gap-6 md:px-16">
            {sections.map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 * (index + 1) }}
                className="relative bg-gray-100 w-full p-6 py-3 px-6 sm:px-12 md:px-20 lg:px-24 rounded-lg"
              >
                <div className="space-y-2">
                  <h2 className="text-xl md:text-xl font-semibold text-unleash-blue font-fredoka">
                    {section.title}
                  </h2>
                  <div className="text-black leading-relaxed pb-2">
                    {section.content}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default SafetyTips;
