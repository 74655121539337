import "./main.scss";
import paw from "../../assets/img/our_services/paw.png";
import coin_icon from "../../assets/img/our_services/rewards_in_app/coin_icon.png";
import { useState } from "react";
// import { FaRegArrowAltCircleUp } from "react-icons/fa";

function OurServicesCard({ icon_inactive, icon_active, title, description }) {
    const [hovering, setHovering] = useState(false);
    
    return (
        <CardContainer changeOnHover={setHovering} hoverState={hovering}>
            <img src={hovering?icon_active:icon_inactive} alt="Card Icon" className="card-os-icon" />
            <p className={"card-os-title" + (hovering ? "-blue" : "")}>{title}</p>
            <p className={"card-os-description" + (hovering? "-blue" : "")}>{description}</p>
            <img src={paw} alt="Card BG" className="card-os-bg" />
            {/* <div className="card-os-more">More Details<FaRegArrowAltCircleUp className="card-os-more-icon"/></div> */}
        </CardContainer>
    )
}

function RewardsInApp({ img, title, cost }) {
    return (
        <CardContainer>
            <div className="h-40">
                <img src={img} alt="Reward" className="card-re-img" />
            </div>
            <p className="card-re-title">{title}</p>
            <div className="flex flex-col flex-1 justify-end">
                <div className="card-inline">
                    <img src={coin_icon} alt="Coin Icon" className="card-re-cost-icon" />
                    <p className="card-re-cost">{cost}</p>
                </div>
            </div>
        </CardContainer>
    );
}

function CardContainer({ children, changeOnHover, hoverState=false }) {

    const manageHoverChanges = (changeOnHover, value) => {
        if (changeOnHover) {
            changeOnHover(value);
        }
    }

    return (
        <div
            className={"card " + (hoverState?"card-blue" : "")}
            onMouseEnter={(e) => manageHoverChanges(changeOnHover, true)}
            onMouseLeave={(e) => manageHoverChanges(changeOnHover, false)}>
            {children}
        </div>
    );
}

function PetPictures ({id, img}) {
    return (
        <div key={id} className="card-pet" >
            <img src={img} alt="Pet" className="card-pet-img"/>
        </div>
    );
}

function IconText({ icon, title, content }) {
    return (
        <div className="card-it">
            <div className="card-it-inner">
                <img src={icon} alt="Icon" className="card-it-inner-img" />
                <div className="card-it-inner-text">
                    <p className="card-it-inner-text-title">{title}</p>
                    <p className="card-it-inner-text-content">{content}</p>
                </div>
            </div>
        </div>
    );
}

export { OurServicesCard, RewardsInApp, IconText, PetPictures }