import React from "react";
import phones from "../../assets/img/phones.png";
import googlePlay from "../../assets/img/google-play-badge.png";
import appStore from "../../assets/img/app-store-badge.png";
import unleashLogo from "../../assets/img/unleash_banner_white.png";
import emailIcon from "../../assets/img/email.png";
import locationIcon from "../../assets/img/location.png";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import "./main.scss";

export default function FooterSection() {
  return (
    <div className="footer">
      <div className="footer-container">
        {/* Left Section: Logo & Social Media */}
        <div className="footer-logo">
          <img src={unleashLogo} alt="Unleash Logo" className="unleash-logo" />
          <p>Join a vibrant pet-loving community!</p>
          <p>Create profiles, share media, join discussions, <br>
          </br>and explore pet products and services.</p>
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/unleashphofficial" target="_blank" rel="noopener noreferrer">
              <CiFacebook className="social-icon" />
            </a>
            <a href="https://www.instagram.com/unleashphofficial/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
          </div>
        </div>

        {/* Middle Section: Contact Info */}
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>
            <img src={locationIcon} alt="Location Icon" /> 14 Domingo M. Guevara St., Highway <br>
            </br>Hills, Mandaluyong City 1550
          </p>
          <p>
            <img src={emailIcon} alt="Email Icon" /> support@unleash.ph
          </p>
          {/* <p>📞 +63 917 119 8290</p> */}
        </div>

        {/* Right Section: App Promotion */}
        <div className="footer-app">
          <div className="app-promo">
            <img src={phones} alt="Unleash App Preview" className="phones-image" />
          </div>
          <h3 className="app-title">Available on</h3>
          <div className="app-buttons">
            <a href="https://play.google.com/store/apps/details?id=com.hs.unleash&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
              <img src={googlePlay} alt="Google Play" className="store-badge" />
            </a>
            <a href="https://apps.apple.com/ph/app/unleash-pets-lifestyle-app/id6451282476?platform=iphone" target="_blank" rel="noopener noreferrer">
              <img src={appStore} alt="App Store" className="store-badge" />
            </a>
          </div>
        </div>
      </div>

      {/* Background Paw Images */}
      <div className="paw3"></div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>© Copyright 2025 Techforce Solutions Inc. All Rights Reserved.</p>
        <p>Designed by Unleash</p>
      </div>
    </div>
  );
};
