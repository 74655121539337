import React, { useState } from 'react'
import hero from "../../assets/videos/hero_banner.mp4"
import './main.scss'

export default function HeroSection() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className='hero'>
            <div className='hero-container'>
                {/* Clickable Background */}
                <div className="hero-background" onClick={openModal}>
                    <video src={hero} className='hero' autoPlay loop muted />
                </div>
                <div className='hero-contents'>
                    <h1 className='hero-header'>Download Unleash Today!</h1>
                    <p className='hero-text'>Unlock endless fun for you and your pet with our all-in-one app! Shop for trendy pet gear, connect with other pet lovers, and join a lively pet community—all in one place!</p>
                    <div className="hero-btns" onClick={() => window.open("https://unleash.ph/download-link", "_blank")} > DOWNLOAD NOW </div>
                </div>

                {/* Popup Video */}
                {isModalOpen && (
                    <div className="video-modal" onClick={closeModal}>
                        <div className="video-wrapper">
                            <video src={hero} controls autoPlay />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
