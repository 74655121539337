import { useCallback, useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import HeaderWithPaw from "../../components/revamp/Header";

export default function ContactUs() {
    const [purpose, setPurpose] = useState("Select Item");
    const [dropdown, setDropdown] = useState(false);
    const [dropdownError, setDropdownError] = useState("");
    const dropdownRef = useRef(null);

    // State for form fields and validation
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: ""
    });

    const itemClick = (purpose) => {
        setPurpose(purpose);
        setDropdown(false);
        setDropdownError("");
    };

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdown(false);
            if (purpose === "Select Item") {
                setDropdownError("This field is required.");
            }
        }
    }, [purpose]);

    // Attach event listener when dropdown is open
    useEffect(() => {
        if (dropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdown, handleClickOutside]);

    // Handle input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        // Clear error when user starts typing
        setErrors({ ...errors, [id]: "" });
    };

    // Validate on blur
    const handleBlur = (e) => {
        const { id, value } = e.target;
        let newErrors = { ...errors };
    
        // Validate based on the specific field
        switch (id) {
            case "name":
                newErrors.name = value.trim() ? "" : "Name is required";
                break;
            case "email":
                if (!value.trim()) {
                    newErrors.email = "Email is required";
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    newErrors.email = "Email is invalid";
                } else {
                    newErrors.email = "";
                }
                break;
            case "phoneNumber":
                if (value.trim() && !/^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)) {
                    newErrors.phoneNumber = "Enter a valid phone number";
                } else {
                    newErrors.phoneNumber = ""; // Remove error if empty or valid
                }
                break;
            case "message":
                newErrors.message = value.trim() ? "" : "Message is required";
                break;
            default:
                break;
        }
    
        setErrors(newErrors);
    };
    


    // Validate form before submission
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }
        if (!formData.email.trim()) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is invalid";
            isValid = false;
        }
        if (formData.phoneNumber && !/^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = "Enter a valid phone number";
            isValid = false;
        }
        if (!formData.message.trim()) {
            newErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // [FOR BACKEND]
        }
    };

    return (
        <div className="contact-us" id="contact-us">
            <div className="contact-us-inner">
                <div className="half-one">
                    <HeaderWithPaw title="Contact Us" paw={false} justify="LEFT"/>
                    <div className="text">Let us know your thoughts in the form below. Help us improve with your feedbacks or reach out to us for further business inquiries.</div>

                    <div className="mobile">
                        <div className="map-container">
                            <iframe
                                title="google maps"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d640.8711964958865!2d121.05044074675143!3d14.579010178473773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8474d36bd71%3A0x54a37d8878981e2b!2sHighly%20Succeed%20Inc.!5e0!3m2!1sen!2sph!4v1739329703263!5m2!1sen!2sph"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>

                        <div className="details">
                            <div className="location">
                                <img src={require("../../assets/img/contact_us/location.png")} alt="location" className="icon" />
                                <p>14 Domingo M. Guevara St., Highway Hills, Mandaluyong City 1550</p>
                            </div>
                            <div className="contacts">
                                <div className="half">
                                    <img src={require("../../assets/img/contact_us/mail.png")} alt="location" className="icon" />
                                    <p>support@unleash.ph</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="dropdown-section">
                            <label htmlFor="purpose" className="label">I want to</label>
                            <div className="box">
                                <div onClick={() => setDropdown(!dropdown)} className="menu">
                                    {purpose}
                                    <FaChevronDown aria-hidden="true" className="-mr-1 size-5 text-gray-400" />
                                </div>

                                <div className={dropdown ? "menu-items-layer" : "hidden"} ref={dropdownRef}>
                                    <div className="py-1">
                                        <div onClick={() => itemClick("I want to inquire")} className="menu-item-layer"> I want to inquire </div>
                                        <div onClick={() => itemClick("I want to be a partner")} className="menu-item-layer"> I want to be a partner </div>
                                        <div onClick={() => itemClick("I want to be an investor")} className="menu-item-layer"> I want to be an investor </div>
                                        <div onClick={() => itemClick("I want to be a customer")} className="menu-item-layer"> I want to be a customer </div>
                                        <div onClick={() => itemClick("I want to be a merchant")} className="menu-item-layer"> I want to be a merchant </div>
                                        <div onClick={() => itemClick("I want to know more")} className="menu-item-layer"> I want to know more </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {dropdownError ? <p className="error-message">{dropdownError}</p> : <p className="error-message select-none">&nbsp;</p>}

                        <div className="input-layer">
                            <div className="half">
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    onFocus={() => setErrors((prev) => ({ ...prev, name: "" }))}
                                    onBlur={handleBlur}
                                />
                                {errors.name ? <p className="error-message">{errors.name}</p> : <p className="error-message select-none">&nbsp;</p>}
                            </div>
                            <div className="half">
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Email Address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    onFocus={() => setErrors((prev) => ({ ...prev, email: "" }))}
                                    onBlur={handleBlur}
                                />
                                {errors.email ? <p className="error-message">{errors.email}</p> : <p className="error-message select-none">&nbsp;</p>}
                            </div>
                        </div>
                        <div className="input-layer">
                            <div className="half">
                                <input
                                    id="phoneNumber"
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    onFocus={() => setErrors((prev) => ({ ...prev, phoneNumber: "" }))}
                                    onBlur={handleBlur}
                                />
                                {errors.phoneNumber ? <p className="error-message">{errors.phoneNumber}</p> : <p className="error-message select-none">&nbsp;</p>}
                            </div>
                            <div className="half">
                                <input
                                    id="subject"
                                    type="text"
                                    placeholder="Subject"
                                    value={formData.subject}
                                    onChange={handleInputChange}
                                    onFocus={() => setErrors((prev) => ({ ...prev, subject: "" }))}
                                    onBlur={handleBlur}
                                />
                                {errors.subject ? <p className="error-message">{errors.subject}</p> : <p className="error-message select-none">&nbsp;</p>}
                            </div>
                        </div>
                        <div className="input-layer">
                            <textarea
                                id="message"
                                placeholder="Enter Message"
                                value={formData.message}
                                onChange={handleInputChange}
                                onFocus={() => setErrors((prev) => ({ ...prev, message: "" }))}
                                onBlur={handleBlur}
                            />
                        </div>
                        {errors.message ? <p className="error-message">{errors.message}</p> : <p className="error-message select-none">&nbsp;</p>}

                        <button type="submit" form="contact-form" value="Submit" className="submit-button">Send Message</button>
                    </form>
                </div>
                <div className="half-two">
                    <div className="map-container">
                        <iframe
                            title="google maps"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d640.8711964958865!2d121.05044074675143!3d14.579010178473773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8474d36bd71%3A0x54a37d8878981e2b!2sHighly%20Succeed%20Inc.!5e0!3m2!1sen!2sph!4v1739329703263!5m2!1sen!2sph"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>

                    <div className="details">
                        <div className="location">
                            <img src={require("../../assets/img/contact_us/location.png")} alt="location" className="icon" />
                            <p>14 Domingo M. Guevara St., Highway Hills, Mandaluyong City 1550</p>
                        </div>
                        <div className="contacts">
                            <div className="half">
                                <img src={require("../../assets/img/contact_us/mail.png")} alt="location" className="icon" />
                                <p>support@unleash.ph</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}