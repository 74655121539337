import mascot from '../assets/img/privacy_policy/mascot.png'; 
import banner from '../assets/img/privacy_policy/banner.png';
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {
  const sections = [
    {
      number: "01.",
      title: "Information We Collect",
      content: (
        <ul className="list-none mt-2 space-y-2 text-black pl-6 text-justify">
          <li><strong>Account Information:</strong> When you create an account on Unleash, we collect your name, email address, and password to authenticate your identity and secure your account.</li>
          <li><strong><br />Profile Information:</strong> You may choose to provide additional information such as your pet's name, breed, age, and photos to personalize your profile and connect with other pet owners.</li>
          <li><strong><br />User Content:</strong> We collect content that you post, share, or upload to Unleash, including photos, videos, comments, and messages.</li>
        </ul>
      )
    },
    {
      number: "02.",
      title: "Use of Information",
      content: (
        <ul className="list-none mt-2 space-y-2 text-black pl-6 text-justify">
          <li>We use the information we collect to provide, personalize, and improve Unleash’s services, including features, content, and recommendations.</li>
          <li><br />Your account information is used to manage your account, authenticate your identity, and communicate with you about your account and our services.</li>
          <li><br />We use your profile information and user content to customize your experience on Unleash, connect you with other users, and facilitate interactions within the community.</li>
          <li><br />We may use automatically collected information for analytics purposes, such as understanding user demographics and preferences, and to optimize the performance and security of Unleash.</li>
        </ul>
      )
    },
    {
      number: "03.",
      title: "Sharing of Information",
      content: (
        <ul className="list-none mt-2 space-y-2 text-black pl-6 text-justify">
          <li>Your content may be visible to other users of Unleash based on your privacy settings and the nature of your interactions on the platform.</li>
          <li><br />We may share your information with third-party service providers who assist us in operating, maintaining, and improving Unleash, subject to confidentiality agreements.</li>
          <li><br />We may disclose your information in response to legal requests, court orders, or legal process, or to establish, protect, or exercise our legal rights or defend against legal claims.</li>
        </ul>
      )
    },
    {
      number: "04.",
      title: "Your Choices",
      content: (
        <ul className="list-none mt-2 space-y-2 text-black pl-6 text-justify">
          <li>You can manage your privacy settings and control who can see your posts and user content on Unleash.</li>
          <li><br />You may update or delete your account information and user content at any time by accessing your account settings or contacting us directly.</li>
        </ul>
      )
    },
    {
      number: "05.",
      title: "Data Security",
      content: (
        <ul className="list-none mt-2 space-y-2 text-black pl-6 text-justify">
          <li>We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</li>
        </ul>
      )
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <main className="pt-0">
        {/* Hero Section */}
        <div className="relative h-[400px] overflow-hidden">
          <img
            src={banner}
            alt="Privacy Policy Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>

        {/* Privacy Policy Text */}
        <div className="relative pt-12 pb-2 text-center px-6 md:px-12">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl md:text-5xl font-semibold text-unleash-blue mb-2 max-w-3xl mx-auto font-montserrat"
          >
            PRIVACY POLICY
          </motion.h1>
          <br />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-8 md:max-w-5xl mx-auto font-montserrat text-justify md:text-center"
          >
            Thank you for using Unleash, a social media platform dedicated to pet lovers worldwide. At Unleash, we are committed to protecting your privacy and personal information. This comprehensive Privacy Policy explains how we collect, use, and safeguard your data when you use our platform.
          </motion.p>
        </div>

        {/* Content Section */}
        <div className="max-w-[90rem] mx-auto px-6 py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {sections.slice(0, 4).map((section, index) => (
              <motion.div
                key={section.number}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 * (index + 1) }}
                className="relative"
              >
                <div className="flex items-start space-x-6">
                  <span className="text-3xl md:text-4xl font-semibold text-unleash-blue opacity-30 transform -translate-y-1/4 font-montserrat"> {/* Increased translation for more elevation */}
                    {section.number}
                  </span>
                  <div className="space-y-2">
                    <h2 className="text-3xl md:text-4xl font-semibold text-unleash-blue font-fredoka">
                      {section.title}
                    </h2>
                    <p className="text-black leading-relaxed font-montserrat">
                      {section.content}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * (4) }}
              className="relative"
            >
              <div className="flex items-start space-x-6">
                <span className="text-3xl md:text-4xl font-semibold text-unleash-blue opacity-30 transform -translate-y-1/4 font-montserrat"> {/* Adjusted number elevation */}
                  05.
                </span>
                <div className="space-y-2">
                  <h2 className="text-3xl md:text-4xl font-semibold text-unleash-blue font-fredoka">
                    Data Security
                  </h2>
                  <p className="text-black leading-relaxed pl-6 space-y-2 mt-2 text-justify font-montserrat">
                    We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Mascot Section */}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1.5 }}
              className="flex justify-center mt-[-8rem]"
            >
              <img
                src={mascot}
                alt="Unleash Mascot"
                className="w-55 h-55"
              />
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
