import banner from '../assets/img/privacy_policy/banner.png'; 
//import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { motion } from 'framer-motion';
import done from '../assets/img/privacy_policy/done2.png'

const CommunityGuidelines = () => {
  return (
    <div className="min-h-screen bg-white">
      <main className="pt-0">
        {/* Hero Section */}
        <div className="relative h-[400px] overflow-hidden">
          <img
            src={banner}
            alt="Privacy Policy Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>

        {/* Community Guidelines Text */}
        <div className="relative pt-12 pb-2 text-center px-6 md:px-12">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl font-semibold text-unleash-blue mb-2 mx-auto font-montserrat"
          >
            Community Guidelines
          </motion.h1>
          <br />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-6 max-w-[78rem] mx-auto text-justify md:text-center"
          >
            By adhering to these guidelines, we can create a vibrant and welcoming community where pet lovers from all walks of life can come together to share their love for animals. Thank you for being a part of our pet-loving family!
          </motion.p>
        </div>

        {/* Content Section */}
        <div className="max-w-8xl mx-auto px-6 md:px-16 lg:px-32 pt-2 pb-16">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * (4) }}
              className="relative"
            >
              <div className="space-y-2">
                <ul className="list-none mt-2 space-y-4 text-black px-6 text-justify">
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Respect and Kindness: </strong> Treat all members with respect and kindness. Remember that behind every profile is a real person who loves their pet just as much as you do.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>No Harassment or Bullying:</strong> Harassment, bullying, or any form of intimidation will not be tolerated. This includes but is not limited to, name-calling, threatening language, or any behavior intended to harm others.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Keep it Family-Friendly:</strong> This is a platform for pet lovers of all ages. Keep content and language appropriate for a diverse audience, including children and families.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Original Content:</strong> Share only content that you have the right to share. Respect copyright laws and give credit to others when necessary.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>No Spam or Self-Promotion:</strong> Avoid excessive self-promotion or spamming of content. This includes repetitive posting of the same content, soliciting likes or follows, or promoting unrelated products or services.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Safety First:</strong> Ensure the safety of pets and humans alike. Do not share content that could potentially harm animals or encourage unsafe behavior.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Respect Privacy:</strong> Respect the privacy of others. Do not share personal information about yourself or others, including addresses, phone numbers, or any other sensitive information.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Report Inappropriate Content:</strong> If you come across content that violates these guidelines or makes you uncomfortable, please report it to the platform administrators for review.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>No Discrimination:</strong> Discrimination of any kind, including but not limited to race, ethnicity, religion, gender, sexual orientation, or disability, will not be tolerated. Community Collaboration: Help foster a sense of community by engaging with others in a positive and constructive manner. Share helpful tips, celebrate milestones, and offer support to fellow pet lovers.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Follow Platform Policies:</strong> Adhere to the terms of service and guidelines set forth by the platform. Failure to do so may result in the removal of content or suspension of your account.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <img src={done} alt="done" className="w-4 h-4 mt-1" />
                    <div>
                      <strong>Continuous Improvement:</strong> We welcome feedback and suggestions for improving the community experience. Let us know how we can make this platform better for you and your pets.
                    </div>
                  </li>                 
                </ul>
              </div>
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CommunityGuidelines;
