import mascot from '../assets/img/privacy_policy/mascot.png';
import banner from '../assets/img/privacy_policy/banner.png';
import paws from '../assets/img/privacy_policy/paws.png';
import { motion } from 'framer-motion';

const TermsConditions = () => {
  const sections = [
    {
      number: "01.",
      title: "ACCOUNTS",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>1.1.</strong> You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</li>
          <br />
          <li><strong>1.2.</strong> You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</li>
          <img
            src={paws}
            alt="Unleash Mascot"
            className="w-[450px] h-auto object-contain pt-8 ml-[-8rem]"
          />
        </ul>
      )
    },
    {
      number: "02.",
      title: "CONTENT",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>2.1.</strong> Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</li>
          <br />
          <li><strong>2.2.</strong> By posting Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</li>
          <br />
          <li><strong>2.3.</strong> You retain any and all of your rights to any Content you submit, post, or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service.</li>
        </ul>
      )
    },
    {
      number: "03.",
      title: "PAYMENT TERMS",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>3.1.</strong> Payment for products purchased through the Service must be made in accordance with the payment methods specified on the Service.</li>
          <br />
          <li><strong>3.2.</strong> All payments are processed through third-party payment processors. We do not store any credit card information.</li>
          <br />
          <li><strong>3.3.</strong> You agree to pay all fees and charges incurred in connection with your purchases at the rates in effect when the charges were incurred.</li>
        </ul>
      )
    },
    {
      number: "04.",
      title: "INTELLECTUAL PROPERTY",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>4.1.</strong> The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Unleash and its licensors.</li>
        </ul>
      )
    },
    {
      number: "05.",
      title: "LINKS TO OTHER WEBSITES",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>5.1.</strong> Our Service may contain links to third-party web sites or services that are not owned or controlled by Unleash.</li>
          <br />
          <li><strong>5.2.</strong> Unleash has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that [Your Company] shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</li>
        </ul>
      )
    },
    {
      number: "06.",
      title: "TERMINATION",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>6.1.</strong> We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</li>
          <br />
          <li><strong>6.2.</strong> Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply Delete your account.</li>
        </ul>
      )
    },
    {
      number: "07.",
      title: "LIMITATION OF LIABILITY",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>7.1.</strong> In no event shall Unleash, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</li>
        </ul>
      )
    },
    {
      number: "08.",
      title: "DISCLAIMER",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>8.1.</strong> Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</li>
        </ul>
      )
    },
    {
      number: "09.",
      title: "CHANGES",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>9.1.</strong> We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</li>
          <br />
          <li><strong>9.2.</strong> By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</li>
        </ul>
      )
    },
    {
      number: "10.",
      title: "CONTACT US",
      content: (
        <ul className="list-disc mt-2 space-y-2 text-black pl-9 text-justify">
          <li><strong>10.1.</strong> If you have any questions about these Terms, please contact us at support@unleash.ph.</li>
        </ul>
      )
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <main className="pt-0">
        {/* Hero Section */}
        <div className="relative h-[400px] overflow-hidden">
          <img
            src={banner}
            alt="Privacy Policy Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>

        {/* Text */}
        <div className="relative pt-12 pb-4 text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl md:text-5xl font-semibold text-unleash-blue mb-2 max-w-3xl mx-auto font-montserrat"
          >
            Terms and Conditions
          </motion.h1>
          <br />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-8 text-center max-w-[85rem] mx-auto"
          >
            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-8 text-center max-w-[85rem] mx-auto"
          >
            Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are widely used to make websites and apps work more efficiently and to provide information to the owners of the site.
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-8 text-center max-w-[85rem] mx-auto"
          >
            By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
          </motion.p>
        </div>

        {/* Content Section */}
        <div className="max-w-[90rem] mx-auto px-6 py-12 pt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {sections.map((section, index) => (
              <motion.div
                key={section.number}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 * (index + 1) }}
                className="relative"
              >
                <div className="flex items-start space-x-6">
                  <span className="text-3xl md:text-4xl font-semibold text-unleash-blue opacity-30 transform -translate-y-1/4 font-montserrat"> {/* Increased translation for more elevation */}
                    {section.number}
                  </span>
                  <div className="space-y-2">
                    <h2 className="text-3xl md:text-4xl font-semibold text-unleash-blue font-fredoka">
                      {section.title}
                    </h2>
                    <p className="text-black leading-relaxed pb-8">
                      {section.content}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * (4) }}
              className="relative"
            >
              <div className="flex items-start space-x-6">
              </div>
            </motion.div>

            {/* Mascot Section */}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1.5 }}
              className="flex justify-center mt-[-14rem] items-center ml-[10rem]"
            >
              <img
                src={mascot}
                alt="Unleash Mascot"
                className="w-[400px] h-auto object-contain"
              />
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TermsConditions;
