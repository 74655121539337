import { NavLink } from "react-router-dom";
import "./main.scss"
import { RiArrowDropDownFill } from "react-icons/ri";
import logo from "../../assets/img/unleash_banner_white.png"; 
import { NAVIGATION_BAR_MOBILE, NAVIGATION_BAR_WEB } from "../../variables/NavigationList"; 
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import { useScreenWidth } from "../../utilities/custom-functions";
import FooterSection from "../../pages/sections/FooterSection";

const LEFT = "LEFT";
const RIGHT = "RIGHT";

export default function Navbar({ children }) {

    const screenWidth = useScreenWidth();
    const tablet_width = getComputedStyle(document.documentElement).getPropertyValue("--tablet_width");
    const phone_width = getComputedStyle(document.documentElement).getPropertyValue("--phone_width");

    const [burgerMenu, setBurgerMenu] = useState(false);

    const navigationClick = (target) => {
        setBurgerMenu(false);
        setTimeout(() => {
            window.scrollTo({
                top: target === "" ? 0 : document.querySelector(target).offsetTop,
                behavior: "smooth",
            });
        }, 200);
    };

    return (
        <div>
            <div className="custom-navbar">
                {screenWidth > tablet_width ?
                    <>
                        <NavigationLinks navLinks={NAVIGATION_BAR_WEB.left} side={LEFT} onClick={navigationClick} />
                        <NavLink className="custom-navbar-brand" to={"/"} onClick={(e) => navigationClick("")}>
                            <img src={logo} alt="Unleash Logo" className="custom-navbar-brand-img" />
                        </NavLink>
                        <NavigationLinks navLinks={NAVIGATION_BAR_WEB.right} side={RIGHT} onClick={navigationClick} />
                    </>
                    :
                    screenWidth > phone_width ?
                        <>
                            <NavLink className="custom-navbar-brand" to={"/"} onClick={(e) => navigationClick("")}>
                                <img src={logo} alt="Unleash Logo" className="custom-navbar-brand-img" />
                            </NavLink>
                            <NavigationLinks navLinks={NAVIGATION_BAR_MOBILE} onClick={navigationClick} />
                        </>
                        :
                        <>
                            <NavLink className="custom-navbar-brand" to={"/"} onClick={(e) => navigationClick("")}>
                                <img src={logo} alt="Unleash Logo" className="custom-navbar-brand-img" />
                            </NavLink>
                            <div className="burger-menu-container">
                                <IoMenu className="burger-menu" onClick={(e) => setBurgerMenu(!burgerMenu)} />
                            </div>
                            <NavigationLinks className={burgerMenu ? "burgermenu-show" : "burgermenu-hide"} navLinks={NAVIGATION_BAR_MOBILE} onClick={navigationClick} />
                        </>
                }
            </div>
            <div className="custom-navbar-children">
                {children}
            </div>
            <FooterSection />
        </div>
    );
}

function NavigationLinks({ className, navLinks, side = RIGHT, onClick }) {
    return (
        <div className={"custom-navbar-menu" + (side === LEFT ? "-left " : " ") + className}>
            {
                navLinks.map((item) => {
                    if (item.menuType === "single") {
                        return <LinkItem itemName={item.name} itemLink={item.link} dropdown={false} onClick={onClick} jumpTo={item.jumpTo} />;
                    } else {
                        return <DropDownLinkItem name={item.name} linksList={item.items} onClick={onClick}/>;
                    }
                })
            }
        </div>
    );
}

function DropDownLinkItem({ name, linksList, onClick }) {
    const [shown, setShown] = useState(false);
    const [hovering, setHovering] = useState(false);
    return (
        <div className="custom-navbar-menu-dropdown">
            <div className="custom-navbar-menu-dropdown-label" onMouseEnter={e => setShown(true)} onMouseLeave={e => setShown(false)} onMouseDown={e => setShown(!shown)}>
                <div className="custom-navbar-menu-dropdown-label-text">{name} <RiArrowDropDownFill className={"custom-navbar-menu-dropdown-icon" + (shown || hovering ? "-active" : "")} /></div>
            </div>
            {
                (shown || hovering) && linksList.map((item) => {
                    return <LinkItem
                        itemName={item.name}
                        itemLink={item.link}
                        jumpTo={item.jumpTo}
                        dropdown={true}
                        onClick={onClick}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                    />;
                })
            }
        </div>
    );
}

function LinkItem({ onMouseEnter, onMouseLeave, itemName, itemLink, jumpTo, dropdown, onClick }) {
    return (
        <NavLink className={"custom-navbar-menu-" + (dropdown ? "dropdown-item" : "item")} to={itemLink} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={(e) => onClick(jumpTo)}>
            <div className={dropdown ? "custom-navbar-menu-dropdown-item-text" : ""}>{itemName}</div>
        </NavLink>
    );
}