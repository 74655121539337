import banner from '../assets/img/privacy_policy/banner.png';
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { motion } from 'framer-motion';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      <main className="pt-0">
        {/* Hero Section */}
        <div className="relative h-[400px] overflow-hidden">
          <img
            src={banner}
            alt="Privacy Policy Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>

        {/* Cookie Policy Text */}
        <div className="relative pt-12 pb-2 text-center px-6 md:px-12">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl font-semibold text-unleash-blue mb-2 mx-auto font-montserrat"
          >
            COOKIE POLICY
          </motion.h1>
          <br />
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-6 max-w-[79rem] mx-auto text-justify md:text-center"
          >
            This app uses cookies to enhance the user experience and provide personalized services. By using our app, you consent to the use of cookies in accordance with this policy.
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-black mb-8 max-w-[79rem] mx-auto text-justify md:text-center"
          >
            Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are widely used to make websites and apps work more efficiently and to provide information to the owners of the site.
          </motion.p>
        </div>

        {/* Content Section */}
        <div className="max-w-8xl mx-auto px-6 md:px-16 lg:px-32 pt-2 pb-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * (4) }}
              className="relative"
            >
              <div className="space-y-2">
                <h2 className="text-3xl md:text-4xl font-semibold text-unleash-blue font-fredoka">
                  COOKIE USE
                </h2>
                <ul className="list-none mt-2 space-y-4 text-black pl-6 text-justify">
                  <li className="flex items-start gap-2">
                    <IoCheckmarkDoneOutline className="text-unleash-blue mt-1 flex-shrink-0" />
                    <div>
                      <strong>Essential Cookies:</strong> These cookies are necessary for the app to function properly. They enable core functionalities such as security, network management, and accessibility. You cannot opt out of these cookies as they are essential for the operation of the app.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <IoCheckmarkDoneOutline className="text-unleash-blue mt-1 flex-shrink-0" />
                    <div>
                      <strong>Marketing Cookies:</strong> We may use marketing cookies to track visitors across the app. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.
                    </div>
                  </li>
                  <li className="flex items-start gap-2">
                    <IoCheckmarkDoneOutline className="text-unleash-blue mt-1 flex-shrink-0" />
                    <div>
                      <strong>Preference Cookies:</strong> These cookies enable the app to remember information that changes the way the website behaves or looks, such as your preferred language or the region you are in.
                    </div>
                  </li>
                </ul>

              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * (4) }}
              className="relative"
            >
              <div className="space-y-2">
                <h2 className="text-3xl md:text-4xl font-semibold text-unleash-blue font-fredoka">
                  CHANGES
                </h2>
                <ul className="list-none mt-2 space-y-2 text-black pl-6 text-justify">
                  <li>We reserve the right to periodically update our Cookie Policy to align with advancements in technology, evolving legislative requirements, or modifications to our data practices. We strongly recommend that you revisit this page at regular intervals to stay informed about the most recent details concerning our utilization of cookies.</li>
                  <li><br />If you have any questions about our Cookie Policy, please contact us at
                    <a href="mailto:support@unleash.ph" className="text-blue-500"> support@unleash.ph
                    </a>
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CookiePolicy;
