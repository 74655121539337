import { useEffect, useState } from "react";

const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenWidth;
};

const TABLET_WIDTH = getComputedStyle(document.documentElement).getPropertyValue("--tablet_width");
const PHONE_WIDTH = getComputedStyle(document.documentElement).getPropertyValue("--phone_width");

export { useScreenWidth, TABLET_WIDTH, PHONE_WIDTH };