const NAVIGATION_BAR_WEB = {
    left: [
        {
            name: "HOME",
            menuType: "single",
            link: "/",
            jumpTo: ""
        },
        {
            name: "ABOUT US",
            menuType: "single",
            link: "/",
            jumpTo: "#about-us"
        },
        {
            name: "OUR SERVICES",
            menuType: "single",
            link: "/",
            jumpTo: "#our-services",
        },
    ],
    right: [
        {
            name: "JOIN US",
            menuType: "single",
            link: "/",
            jumpTo: "#join-us",
        },
        {
            name: "CONTACT",
            menuType: "single",
            link: "/",
            jumpTo: "#contact-us",
        },
        {
            name: "TERMS AND POLICIES",
            menuType: "dropdown",
            items: [
                {
                    name: "PRIVACY POLICY",
                    menuType: "single",
                    link: "/privacy-policy",
                    jumpTo: ""
                },
                {
                    name: "TERMS AND CONDITIONS",
                    menuType: "single",
                    link: "/terms-and-conditions",
                    jumpTo: ""
                },
                {
                    name: "COMMUNITY GUIDELINES",
                    menuType: "single",
                    link: "/community-guidelines",
                    jumpTo: ""
                },
                {
                    name: "SAFETY TIPS",
                    menuType: "single",
                    link: "/safety-tips",
                    jumpTo: ""
                },
                {
                    name: "COOKIE POLICY",
                    menuType: "single",
                    link: "/cookie-policy",
                    jumpTo: ""
                }
            ],
        }
    ]
}

const NAVIGATION_BAR_MOBILE = [...NAVIGATION_BAR_WEB.left, ...NAVIGATION_BAR_WEB.right];

export { NAVIGATION_BAR_WEB, NAVIGATION_BAR_MOBILE };
